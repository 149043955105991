import React from "react"
import SEO from "../../components/seo"
import Header from "../../components/header"

import WorkFooter from "../../components/portfolioItems/worksFooter"


import Herospace from "../../../assets/images/portfolio_items/car4you/car4you.png"
import car4you1 from "../../../assets/images/portfolio_items/car4you/car4you1.png"
import car4you2 from "../../../assets/images/portfolio_items/car4you/car4you2.png"
import car4you3 from "../../../assets/images/portfolio_items/car4you/car4you3.png"
import car4you4 from "../../../assets/images/portfolio_items/car4you/car4you4.png"

export default () => 
<div>
    <SEO title={'Car 4 ICON Worldwide | Car4You - Mobile App Development'} 
    description="The Swiss unique mobile app to buy and sell cars, Car4You: discover all its secrets on ICON's portfolio!"
    canonical={'https://icon-worldwide.com/works/car4you'}/>
        <Header bgcolor="#292929"/>
    <div id="portfolio-item">
        <div className="herospace">
            <img src={Herospace} alt="car4you, ICON Worldwide, mobile app development, portfolio" title="ICON Worldwide portfolio, mobile app development, car4you"/>
            <div className="title"><h1>Car 4 You<span></span></h1>
            <h2>The Swiss unique mobile app to buy and sell cars</h2>
            </div>
        </div>
        <div className="portfolio-inner">
        <p>The largest online used car website in Switzerland, the Car4You app is on mover 1 million phones. Our unique mobile app allowed users to buy and sell cars directly from their phones.</p>
        <div className="portfolio-images">
            <img src={car4you1} alt="car4you, ICON Worldwide, mobile app development, portfolio" title="ICON Worldwide portfolio, mobile app development, car4you"/>
            <img src={car4you3} alt="car4you, ICON Worldwide, mobile app development, portfolio" title="ICON Worldwide portfolio, mobile app development, car4you"/>
            <img src={car4you2} alt="car4you, ICON Worldwide, mobile app development, portfolio" title="ICON Worldwide portfolio, mobile app development, car4you"/>
            <img src={car4you4} alt="car4you, ICON Worldwide, mobile app development, portfolio" title="ICON Worldwide portfolio, mobile app development, car4you"/>
        </div>
        <WorkFooter previous="qiio" next="university-of-st-gallen"/>
        </div>
    </div>
</div>